var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import useIsFirstInteraction from "./useIsFirstInteraction";
var LazyLoadComponent = function LazyLoadComponent(_a) {
  var loadComponent = _a.loadComponent,
    _b = _a.waitInterActive,
    waitInterActive = _b === void 0 ? true : _b,
    _c = _a.renderOnSSR,
    renderOnSSR = _c === void 0 ? true : _c;
  if (__IS_SSR__ && renderOnSSR) {
    return loadComponent();
  }
  var isFirstInteraction = useIsFirstInteraction();
  var _d = __read(useState(false), 2),
    shouldLoad = _d[0],
    setShouldLoad = _d[1];
  var targetRef = useRef(null);
  useEffect(function () {
    if (waitInterActive && !isFirstInteraction) {
      return;
    }
    var observer = new IntersectionObserver(function (entries) {
      var entry = entries[0];
      if (entry.isIntersecting) {
        setShouldLoad(true);
        observer.disconnect();
      }
    }, {
      root: null,
      rootMargin: "0px 0px 50px 0px",
      threshold: 0.99
    });
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    return function () {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [shouldLoad, isFirstInteraction]);
  return _jsx("div", {
    ref: targetRef,
    children: shouldLoad ? loadComponent() : _jsx(_Fragment, {})
  });
};
export default LazyLoadComponent;