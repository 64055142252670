var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import SlideContent from "./SlideContent/SlideContent";
import stl from "../../HomePage.module.css";
import useIsMobile from "@hooks/useIsMobile";
import useIsFirstInteraction from "../../../../common/hooks/useIsFirstInteraction";
import { useEffect, useState } from "react";
import { thumbUrl } from "../../../../utils/imagesHelpers";
var UspSlider = function UspSlider(_a) {
  var usp_slider = _a.usp_slider,
    _b = _a.isEager,
    isEager = _b === void 0 ? false : _b;
  var isFirstInteraction = useIsFirstInteraction();
  var isMobile = useIsMobile();
  if (!(usp_slider === null || usp_slider === void 0 ? void 0 : usp_slider.images) || !(usp_slider === null || usp_slider === void 0 ? void 0 : usp_slider.title)) return null;
  var has3 = usp_slider.images.length <= 3;
  var _c = __read(useState(usp_slider.images.slice(0, isMobile ? 2 : 4)), 2),
    images = _c[0],
    setImages = _c[1];
  useEffect(function () {
    if (!isFirstInteraction) return;
    setImages(usp_slider.images);
  }, [isFirstInteraction]);
  var items = images.map(function (img) {
    return {
      bgColor: "linear-gradient(0deg, rgba(241,246,252,1) 0%, rgba(249,251,254,1) 100%)",
      imageUrl: thumbUrl(img.image, "homepage-usp-slider-images-v1", isMobile ? "0x300" : "0x426"),
      progressiveImageUrl: !isEager ? null : thumbUrl(img.image, "homepage-usp-slider-images-v1", isMobile ? "0x154" : "0x300")
    };
  });
  return _jsx(SlideContent, {
    isEager: isEager,
    has3: has3,
    titleClass: stl.CustomContainer,
    containerClass: stl.CustomContainer,
    title: usp_slider.title,
    items: items
  });
};
export default UspSlider;