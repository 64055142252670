var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import useIsFirstInteraction from "../common/hooks/useIsFirstInteraction";
import { CDN_LINK } from "@globalConfig";
import ZoomableImage from "@/components/carTracking/components/newPostBigSlider/components/ZoomableImage";
var DEFAULT_ERROR_IMAGE = CDN_LINK + "/assets/images/no_img.jpg";
var MAX_ERRORS_COUNT = 3;
var counters = {};
var onError = function onError(imagePath, errorImage) {
  return function (_a) {
    var currentTarget = _a.currentTarget;
    counters[imagePath] = counters[imagePath] || 0;
    if (counters[imagePath] >= MAX_ERRORS_COUNT) {
      return;
    }
    counters[imagePath]++;
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = errorImage || DEFAULT_ERROR_IMAGE;
  };
};
var ProgressiveImage = function ProgressiveImage(_a) {
  var _b = _a.progressiveSmallImage,
    progressiveSmallImage = _b === void 0 ? null : _b,
    _c = _a.waitUntilInteractive,
    waitUntilInteractive = _c === void 0 ? false : _c,
    _d = _a.noInitialDiv,
    noInitialDiv = _d === void 0 ? false : _d,
    errorImage = _a.errorImage,
    src = _a.src,
    _e = _a.hasZoom,
    hasZoom = _e === void 0 ? false : _e,
    loading = _a.loading,
    // pass loading attribute
    imgProps = __rest(_a, ["progressiveSmallImage", "waitUntilInteractive", "noInitialDiv", "errorImage", "src", "hasZoom", "loading"]);
  var initialSrc = waitUntilInteractive ? null : src;
  // const Tag = hasZoom ? "ZoomableImage" : "";
  var _f = __read(useState(initialSrc), 2),
    currentImage = _f[0],
    setCurrentImage = _f[1];
  var _g = __read(useState(loading), 2),
    currentLoading = _g[0],
    setCurrentLoading = _g[1]; // Initialize with the provided loading prop
  var isFirstInteraction = useIsFirstInteraction();
  useEffect(function () {
    if (waitUntilInteractive && isFirstInteraction) {
      setCurrentImage(src);
      // setCurrentLoading(undefined); // Update loading prop
    }
  }, [isFirstInteraction]);
  useEffect(function () {
    setCurrentImage(src);
    //  setCurrentLoading(undefined); // Update loading prop
  }, [src]); // Add loading to dependencies
  // to Handel SVG
  if (!currentImage) {
    if (imgProps.width && imgProps.height) {
      return _jsx("div", {
        className: imgProps.className,
        style: {
          width: noInitialDiv ? "auto" : imgProps.width + "px",
          height: noInitialDiv ? "auto" : imgProps.height + "px",
          backgroundColor: "transparent",
          display: "inline-block"
        }
      });
    }
    return null;
  }
  return hasZoom ? _jsx(ZoomableImage, {
    children: _jsx("img", __assign({
      className: "hasEvents",
      src: currentImage,
      // @ts-ignore
      style: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: progressiveSmallImage ? "url(\"".concat(progressiveSmallImage, "\")") : "none",
        backgroundColor: "transparent",
        backgroundPosition: "center"
      },
      // @ts-ignore
      loading: currentLoading
    }, imgProps))
  }) : _jsx("img", __assign({
    src: currentImage,
    // @ts-ignore
    style: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: progressiveSmallImage ? "url(\"".concat(progressiveSmallImage, "\")") : "none",
      backgroundColor: "transparent",
      backgroundPosition: "center"
    },
    // @ts-ignore
    loading: currentLoading
  }, imgProps));
};
export default ProgressiveImage;