var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useGetTranslations } from "@/common/Contexts/LanguageContext";
import namespaces from "@/i18n/namespaces";
import { FF_DEBUG_TRANSLATION } from "@globalConfig";
var translationFuncCreator = function translationFuncCreator(translations_data) {
  return function (key, params) {
    var _a, _b;
    if (params === void 0) {
      params = {};
    }
    var lbl = (_b = (_a = translations_data === null || translations_data === void 0 ? void 0 : translations_data[params.ns]) === null || _a === void 0 ? void 0 : _a[key]) !== null && _b !== void 0 ? _b : null;
    if (lbl === null) {
      namespaces.forEach(function (ns) {
        var _a, _b;
        if (lbl === null) {
          lbl = (_b = (_a = translations_data === null || translations_data === void 0 ? void 0 : translations_data[ns]) === null || _a === void 0 ? void 0 : _a[key]) !== null && _b !== void 0 ? _b : null;
        }
      });
    }
    if (lbl === null) {
      lbl = key;
    }
    lbl = typeof lbl === "string" ? lbl : JSON.stringify(key);
    var finalText = (lbl || "").replace(/{{(.*?)}}/g, function (match, p1) {
      return params[p1.trim()] || match;
    });
    if (__IS_CSR__ && window.location.href.includes("/en")) {
      var flag = FF_DEBUG_TRANSLATION || document.cookie.includes("debug_tr") || window.location.href.includes("debug_tr");
      if (flag) {
        return "**".concat(finalText, "**");
      }
    }
    return finalText;
  };
};
// handel old Class React Components using HOC
export var withTranslation = function withTranslation(groups) {
  return function (WrappedComponent) {
    // This is now a functional component
    return function TranslatedComponent(props) {
      var translation_data = useGetTranslations();
      // Return the WrappedComponent (which is a class component) with the translation function
      return _jsx(WrappedComponent, __assign({}, props, {
        t: translationFuncCreator(translation_data)
      }));
    };
  };
};
// Modern React Hooks
var useTranslation = function useTranslation(ns) {
  if (ns === void 0) {
    ns = ["web_common"];
  }
  var translations = useGetTranslations();
  return {
    t: translationFuncCreator(translations)
  };
};
export default useTranslation;