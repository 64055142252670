var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var SizeArray = ["0x99", "0x154", "0x300", "0x426", "0x683", "0x960"];
export var thumbUrl = function thumbUrl(url, prefix, size, force) {
  if (force === void 0) {
    force = false;
  }
  if (!url) return null;
  if (url.includes("no_img")) return url;
  // Check if the URL includes any size from the SizeArray
  var matchedSize = SizeArray.find(function (s) {
    return url.includes(s);
  });
  if (matchedSize) {
    // If it does, replace that size with the new size
    return url.replace(matchedSize, size);
  } else if (url.includes("00x00")) {
    // If it doesn't, keep the existing logic of replacing "00x00" with the new size
    return url.replace("00x00", size);
  }
  // Parse the input URL
  var originalUrl = new URL(url);
  if (force === false && !originalUrl.pathname.includes('photos-thumbs')) return url;
  // Construct the new pathname by inserting prefix and size into the existing path
  // Split the original pathname to remove the leading part specific to the current format
  // Example split: ['/syarah', '/online', '/hero', '/1710158609-181.webp']
  var pathSegments = originalUrl.pathname.split('/').filter(function (segment) {
    return segment;
  }); // filter to remove any empty strings due to leading '/'
  // Rebuild the path with new structure
  var newPathname = "/photos-thumbs/".concat(prefix, "/").concat(size, "/").concat(pathSegments.join('/'));
  // Construct the new URL
  var newUrl = "".concat(originalUrl.protocol, "//").concat(originalUrl.host).concat(newPathname);
  return newUrl;
};
export var processImageUrl = function processImageUrl(imageUrl) {
  if (!imageUrl) return null;
  var searchString = "#testImage";
  // Check if the code is running in a browser environment and the URL contains the test query parameter.
  if (typeof window !== "undefined" && window.location.href.includes(searchString)) {
    // If the condition is met, call the 'convertToOld' function to modify the URL.
    var modifiedImageUrl = convertToOld(imageUrl);
    return modifiedImageUrl;
  } else {
    // If the condition is not met, return the original URL.
    return imageUrl;
  }
};
var convertToOld = function convertToOld(path) {
  // Check if the path includes "photos-thumbs". If not, return it unchanged.
  if (!path.includes("photos-thumbs")) return path;
  // Split the URL path into components.
  var _a = __read(path.split("/"), 6),
    _ = _a[0],
    __ = _a[1],
    ___ = _a[2],
    basePath = _a[3],
    version = _a[4],
    size = _a[5];
  // Modify the URL path as needed.
  var parts = path.split("/");
  parts.splice(-1, 0, size);
  var splitUrl = parts.join("/").replace("".concat(basePath, "/").concat(version, "/").concat(size, "/"), "").split(".");
  var newUrl = splitUrl.slice(0, splitUrl.length - 1).join(".");
  // Return the modified URL.
  return newUrl;
};