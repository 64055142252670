var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import cookieClient from "react-cookies";
import { useState } from "react";
var COOKIE_NANE = "webview_v2";
export function isRealWebView() {
  return true;
  var ua = navigator.userAgent.toLowerCase();
  // iOS WebView detection (for modern iOS versions)
  var isIosWebView = /iphone|ipod|ipad/.test(ua) && !ua.includes('safari');
  // Android WebView detection (for modern Android versions)
  var isAndroidWebView = /android/.test(ua) && ua.includes('wv');
  // Feature-based detection for React Native
  var isReactNative = typeof window.ReactNativeWebView !== 'undefined';
  return isIosWebView || isAndroidWebView || isReactNative;
}
export default function useIsWebView() {
  var location = useLocation();
  var queryString = location.search;
  var params = new URLSearchParams(queryString);
  var _a = __read(useState(!!params.get("webview")), 2),
    isWebView = _a[0],
    setIsWebView = _a[1];
  useEffect(function () {
    if (cookieClient.load(COOKIE_NANE)) {
      setIsWebView(true);
    } else if (isWebView && isRealWebView()) {
      cookieClient.save(COOKIE_NANE, "1", {
        path: "/",
        maxAge: 3600 * 24 * 365 * 10,
        // Cookie will expire after 10 years
        sameSite: true
      });
    } else if (isWebView) {
      console.warn('ignored webview due to user agent');
    }
  }, [isWebView]);
  return isWebView;
}