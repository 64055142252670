import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/** @format */
import stl from "../../HomePage.module.css";
import HeroArea2 from "./HeroArea2";
import useIsMobile from "@hooks/useIsMobile";
import { useLang } from "../../../../common/Contexts/LanguageContext";
import { thumbUrl } from "../../../../utils/imagesHelpers";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
var SingleImageBanner = function SingleImageBanner(_a) {
  var hero = _a.hero;
  var isMobile = useIsMobile();
  var lang = useLang();
  return _jsxs("div", {
    style: {
      background: hero.gradient_direction && hero.gradient_enable !== 0 ? "linear-gradient(".concat(hero.gradient_direction, ", ").concat(hero.primary_banner_color, " 0%, ").concat(hero.secondary_banner_color, " 100%)") : hero.primary_banner_color
    },
    className: stl.slide,
    children: [isMobile ? _jsx(ProgressiveImage, {
      loading: "eager",
      src: thumbUrl(hero.banner_img_mobile, "hero-v1", "0x426", true),
      progressiveSmallImage: thumbUrl(hero.banner_img_mobile, "hero-v1", "0x154", true),
      width: 390,
      height: 366,
      alt: ""
    }) : _jsx(ProgressiveImage, {
      loading: "eager",
      src: thumbUrl(hero.banner_img_desktop, "hero-v1", "0x960", true),
      progressiveSmallImage: thumbUrl(hero.banner_img_desktop, "hero-v1", "0x300", true),
      width: 1200,
      height: 675,
      alt: ""
    }), hero.cta_enable !== 0 && _jsx("div", {
      className: ["SlideLinkContainer", stl.slidContw].join(" "),
      children: _jsx("a", {
        className: "redBtn22",
        style: {
          backgroundColor: hero.cta_background || undefined,
          borderColor: hero.cta_background || undefined
        },
        href: "".concat(lang === "en" ? "/en" : "").concat(hero.cta_filter ? "/filters" + hero.cta_filter : hero.cta_url_path),
        children: hero.cta_text
      })
    })]
  });
};
var MultiImageBanner = function MultiImageBanner(_a) {
  var heros = _a.heros;
  var isMobile = useIsMobile();
  var lang = useLang();
  return _jsx(HeroArea2, {
    children: heros.map(function (item, idx) {
      var gradientColor = item.gradient_direction && item.gradient_enable !== 0 ? "linear-gradient(".concat(item.gradient_direction, ", ").concat(item.primary_banner_color, " 0%, ").concat(item.secondary_banner_color, " 100%)") : item.primary_banner_color;
      return _jsxs("div", {
        style: {
          background: gradientColor
        },
        className: stl.slide,
        children: [isMobile ? _jsx(ProgressiveImage, {
          loading: "eager",
          fetchpriority: "high",
          src: thumbUrl(item.banner_img_mobile, "hero-v1", "0x426", true),
          progressiveSmallImage: thumbUrl(item.banner_img_mobile, "hero-v1", "0x154", true),
          width: 390,
          height: 366,
          alt: ""
        }) : _jsx(ProgressiveImage, {
          loading: "eager",
          fetchpriority: "high",
          src: thumbUrl(item.banner_img_desktop, "hero-v1", "0x960", true),
          progressiveSmallImage: thumbUrl(item.banner_img_desktop, "hero-v1", "0x300", true),
          width: 1200,
          height: 675,
          alt: (item === null || item === void 0 ? void 0 : item.cta_text) ? item === null || item === void 0 ? void 0 : item.cta_text : "banner Image"
        }), item.cta_enable !== 0 && _jsx("div", {
          className: "SlideLinkContainer",
          children: _jsx("a", {
            style: {
              backgroundColor: item.cta_background ? item.cta_background : undefined,
              borderColor: item.cta_background ? item.cta_background : undefined
            },
            className: "redBtn22 ",
            href: "".concat(lang === "en" ? "/en" : "").concat(item.cta_filter ? "/filters" + item.cta_filter + "#referral_location=Home&browse_variant=hero_area&variant_value=".concat(item === null || item === void 0 ? void 0 : item.id) : item.cta_url_path + "#referral_location=Home&browse_variant=hero_area&variant_value=".concat(item === null || item === void 0 ? void 0 : item.id)),
            children: item.cta_text
          })
        })]
      }, "U" + idx);
    })
  });
};
var HeroAreaWrapper = function HeroAreaWrapper(props) {
  var heros = props.data.heros;
  return _jsxs(_Fragment, {
    children: [(heros === null || heros === void 0 ? void 0 : heros.length) === 1 && _jsx(SingleImageBanner, {
      hero: heros[0]
    }), (heros === null || heros === void 0 ? void 0 : heros.length) > 1 && _jsx(MultiImageBanner, {
      heros: heros
    })]
  });
};
export default HeroAreaWrapper;