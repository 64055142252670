import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import stl from "./SlideContent.module.css";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
var SlideContent = function SlideContent(_a) {
  var title = _a.title,
    items = _a.items,
    isEager = _a.isEager,
    titleClass = _a.titleClass,
    containerClass = _a.containerClass,
    has3 = _a.has3;
  return _jsxs("div", {
    className: stl.pCont,
    children: [_jsx("div", {
      className: titleClass,
      children: isEager ? _jsx("h1", {
        className: "big",
        children: title
      }) : _jsx("h2", {
        className: "big",
        children: title
      })
    }), _jsx("div", {
      className: [containerClass || "", stl.container, has3 ? stl.has3 : ""].join(" "),
      children: items === null || items === void 0 ? void 0 : items.map(function (item, idx) {
        return _jsxs("div", {
          className: stl.cardContainer,
          style: {
            background: item.bgColor,
            opacity: item.hide ? "0" : "1"
          },
          children: [_jsx(ProgressiveImage, {
            src: item.imageUrl,
            progressiveSmallImage: item.progressiveImageUrl,
            width: 267,
            height: 267,
            loading: isEager ? "eager" : "lazy",
            fetchpriority: isEager ? "high" : "low",
            alt: item.title || item.imageUrl
          }), item.title && _jsx("strong", {
            children: item.title
          }), item.text && _jsx("p", {
            children: item.text
          })]
        }, idx);
      })
    })]
  });
};
export default SlideContent;